import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 110px 0 0 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 50px;

  grid-auto-flow: column;

  width: 100%;

  @media (max-width: 1080px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    grid-gap: 0;
    grid-template-columns: unset;
    grid-auto-flow: unset;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Intro = styled.div`
  width: 100%;

  padding: 0 0 100px 0;
  margin: 0 auto;

  & .text {
    width: 100%;
    max-width: 800px;
    text-align: center;

    padding: 0;
    margin: 0 auto;

    font-size: 32px;
    line-height: 115%;
    letter-spacing: 0.01em;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      // padding: 40px 20px 0 20px;
      padding: 0 20px;

      font-size: 24px;
      line-height: 27px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 45px 0;
  }
`;

const PageLink = styled.div`
  margin: 0 0 125px 0;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 0 60px 0;
  }

  & .title h2 {
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.01em;

    @media (max-width: 1300px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  & .text p {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & img.portrait {
    max-height: 600px;
    object-fit: contain;
  }

  &:nth-of-type(even) {
    & .image {
      grid-column: 1 / 5;
    }

    & .text-container {
      grid-column: 5 / 9;
    }
  }

  &:nth-of-type(odd) {
    & .image {
      grid-column: 5 / 9;
    }

    & .text-container {
      grid-column: 1 / 5;
    }
  }

  @media (max-width: 1080px) {
    &:nth-of-type(odd),
    &:nth-of-type(even) {
      & .image,
      & .text-container {
        width: 100%;
      }
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .read-more {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #8a8a87;
  }

  @media (max-width: 1080px) {
    margin: 20px 0 0 0;
  }

  @media (max-width: 768px) {
    padding: 0 20px;

    & h2 {
      font-size: 26px;
      line-height: 30px;
    }
  }
`;

const SingleAward = styled.div`
  flex: 33.333%;
  max-width: 33.333%;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex: 50%;
    max-width: 50%;
  }
`;

const SingleClient = styled.div`
  flex: 20%;
  max-width: 20%;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex: 33.333%;
    max-width: 33.333%;
  }
`;

const Section = styled.div`
  width: 100%;
  margin: 0 0 110px 0;

  & .text-container {
    max-width: 760px;
    margin: 0 auto 25px auto;
    padding: 0 20px;

    text-align: center;

    & h2 {
      font-size: 48px;
      font-weight: 400;
      line-height: 55px;
      letter-spacing: 0.01em;

      @media (max-width: 1300px) {
        font-size: 36px;
        line-height: 48px;
      }

      @media (max-width: 900px) {
        font-size: 26px;
        line-height: 30px;
      }
    }

    & p {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.04em;
      text-align: center;

      @media (max-width: 1300px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const WhoWeAreContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("mission");
  }, []);

  const content = data.prismicMission.data.body.map((content, index) => {
    if (content.slice_type === "section") {
      return (
        <PageLink key={content.id}>
          <Grid>
            <div className="image">
              <Link to={content.primary.page_link.url}>
                {content.primary.image.fluid !== null && (
                  <img
                    className={ImageOrientation(content.primary.image)}
                    srcSet={content.primary.image.fluid.srcSetWebp}
                    src={content.primary.image.fluid.srcWebp}
                    alt={content.primary.image.alt}
                    loading="lazy"
                  />
                )}
              </Link>
            </div>
            <TextContainer className="text-container">
              <Link to={content.primary.page_link.url}>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_title.html,
                  }}
                />

                <div
                  className="text caslon-doric"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_text.html,
                  }}
                />

                <p className="caslon-doric read-more">Read More</p>
              </Link>
            </TextContainer>
          </Grid>
        </PageLink>
      );
    }

    if (content.slice_type === "video_section") {
      return (
        <PageLink key={content.id}>
          <Grid>
            <div className="image">
              <Link to={content.primary.page_link.url}>
                {content.primary.vimeo_mp4_file.url !== "" && (
                  <video
                    src={content.primary.vimeo_mp4_file.url}
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="metadata"
                  />
                )}
              </Link>
            </div>
            <TextContainer className="text-container">
              <Link to={content.primary.page_link.url}>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_title.html,
                  }}
                />

                <div
                  className="text caslon-doric"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.linked_page_text.html,
                  }}
                />
              </Link>
            </TextContainer>
          </Grid>
        </PageLink>
      );
    }
  });

  const awards = data.prismicMission.data.awards.map((content, index) => (
    <SingleAward key={`single_award_${index}`}>
      <img
        src={content.award_image.url}
        alt={content.award_image.alt}
        loading="lazy"
      />
    </SingleAward>
  ));

  const clients = data.prismicMission.data.clients.map((content, index) => (
    <SingleClient key={`client_award_${index}`}>
      <img
        src={content.client_image.url}
        alt={content.client_image.alt}
        loading="lazy"
      />
    </SingleClient>
  ));

  return (
    <>
      <Helmet
        title={`Who We Are – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Who We Are – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Who We Are – Park Village`,
          },
        ]}
      />
      <Container>
        <Intro>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicMission.data.text.html,
            }}
          />
        </Intro>

        {content}

        <Section>
          <div className="text-container">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicMission.data.awards_title.html,
              }}
            />
            <div
              className="caslon-doric"
              dangerouslySetInnerHTML={{
                __html: data.prismicMission.data.awards_text.html,
              }}
            />
          </div>
          <Flex>{awards}</Flex>
        </Section>

        <Section>
          <div className="text-container">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicMission.data.clients_title.html,
              }}
            />
            <div
              className="caslon-doric"
              dangerouslySetInnerHTML={{
                __html: data.prismicMission.data.clients_text.html,
              }}
            />
          </div>
          <Flex>{clients}</Flex>
        </Section>
      </Container>
    </>
  );
};

const WhoWeAre = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <WhoWeAreContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(WhoWeAre);

export const query = graphql`
  {
    prismicMission {
      data {
        text {
          html
        }
        body {
          ... on PrismicMissionBodySection {
            id
            slice_type
            primary {
              image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  width
                  height
                }
                alt
              }
              linked_page_text {
                html
              }
              linked_page_title {
                html
              }
              page_link {
                url
                id
              }
            }
          }
          ... on PrismicMissionBodyVideoSection {
            id
            slice_type
            primary {
              vimeo_mp4_file {
                url
              }
              page_link {
                url
                id
              }
              linked_page_title {
                html
              }
              linked_page_text {
                html
              }
            }
          }
        }
        awards_title {
          html
        }
        awards_text {
          html
        }
        awards {
          award_image {
            alt
            url
          }
        }
        clients_title {
          html
        }
        clients_text {
          html
        }
        clients {
          client_image {
            alt
            url
          }
        }
      }
    }
  }
`;
